import { invalidateToken, logoutUser } from "../actions/authActions";
import store from "../store";
import { getFormattedMessage } from "../components/utils";
import { getXUserAgentHeader } from "../utils/api.util";

export const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      let error = data.description || data.desc || data.code;
      switch (data.error) {
        case Exception.DOMAIN_ERROR: {
          error = ExceptionResponse.DOMAIN_ERROR_RESPONSE;
          break;
        }
        case Exception.INSUFFICIENT_QUOTA: {
          error = ExceptionResponse.INSUFFICIENT_QUOTA_RESPONSE;
          break;
        }
        case Exception.TOKEN_ERROR: {
          store.dispatch(logoutUser());
          store.dispatch(invalidateToken());
          return exception("");
        }
        default:
          break;
      }
      if (error) return exception(error);
      return exception(Exception.UNKNOWN_ERROR);
    }
    return data;
  });
};

export const getAuthHeadersForMethod = (methodType, authToken, region) => {
  let headers = {
    "Content-Type": "application/json",
    "X-Auth-Token": authToken,
    "X-User-Agent": getXUserAgentHeader(),
  };
  if (region) {
    headers = {
      ...headers,
      Region: region,
    };
  }
  return {
    method: methodType,
    headers: headers,
  };
};

export const exception = (error) => {
  return Promise.reject(error);
};

export const Exception = {
  NETWORK_FAILURE: "TypeError: Failed to fetch",
  DOMAIN_ERROR: "DomainNotFound",
  TOKEN_ERROR: "TokenInvalid",
  UNKNOWN_ERROR: "Unknown server error",
  INSUFFICIENT_QUOTA: "INSUFFICIENT_QUOTA",
};

Object.freeze(Exception);

export const ExceptionResponse = {
  NETWORK_FAILURE_RESPONSE: getFormattedMessage("error-unable-to-connect"),
  DOMAIN_ERROR_RESPONSE: getFormattedMessage(
    "order-not-exist-in-partner-account"
  ),
  INSUFFICIENT_QUOTA_RESPONSE: getFormattedMessage("insufficient_quota"),
};

Object.freeze(ExceptionResponse);

export const handleApiError = (err) => {
  if (String(err) === Exception.NETWORK_FAILURE) {
    return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE);
  }
  return exception(String(err));
};
