import UAParser from 'ua-parser-js';

export const getDeviceInfo = (() => {
  let result: undefined | UAParser.IResult = undefined;
  if (result === undefined) {
    const parser = new UAParser();
    result = parser.getResult();
  }

  return () => result;
})() as () => UAParser.IResult;