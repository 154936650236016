import { getCurrentLocale } from "../components/utils";
import { getAppVersion } from "../helpers/env.helper";
import { getDeviceInfo } from "./device.util";

export const UA_CLIENT = 'partner_panel';
export const UA_TP = 'titan';

/**
 * Return necessary properties for X-User-Agent header
 */
export const getXUserAgentHeaderProps = () => {
  const deviceInfo = getDeviceInfo();
  console.log(deviceInfo,"here")
  const { os, browser, device } = deviceInfo;

  return {
    client: UA_CLIENT,
    tp: UA_TP,
    os: os.name,
    osVersion: os.version,
    browser: browser.name,
    browserVersion: browser.version,
    deviceModel: device.model,
    appVersion: getAppVersion(),
    locale: getCurrentLocale(),
  }
}

/**
 * Return X-User-Agent header string
 */
export const getXUserAgentHeader = (() => {
  const userAgentProps = getXUserAgentHeaderProps();
  const xUserAgentStr = Object.entries(userAgentProps)
    .map(([key, value]) => `${key}=${value}`)
    .join(';');
  return () => xUserAgentStr;
})();