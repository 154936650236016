import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { Checkbox, Popover } from "antd";
import { ReactComponent as FilterIcon } from "../../assets/filter.svg";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import styles from "./styles.module.css";
import { ProtocolLog } from "../../reducers/types/logsType";
import { dateTimeFormat } from "../../utils/helper";
import { FormattedMessage } from "react-intl";
import { downloadCSV } from "../../utils/csvUtils";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { transformLogsToCSV } from "./utils";

// Filters logs based on selected protocols and statuses
const filterLogs = (
  logs: ProtocolLog[],
  filters: { protocols: string[]; statuses: string[] }
): ProtocolLog[] => {
  const { protocols, statuses } = filters;

  return logs.filter(
    (log) =>
      // Include logs matching selected protocols or all if none selected
      (!protocols.length || protocols.includes(log.protocol)) &&
      // Include logs matching selected statuses or all if none selected
      (!statuses.length || statuses.includes(log.status || ""))
  );
};

// Static label mappings for filters
const protocolLabelIds = { imap: "imap", smtp: "smtp", pop: "pop" };
const statusLabelIds = { pass: "pass", fail: "fail" };

// Filter component
const Filter: React.FC<{
  options: string[];
  selected: string[];
  onChange: (values: string[]) => void;
  labelIds: { [key: string]: string };
}> = ({ options, selected, onChange, labelIds }) => {
  const isActive = selected.length > 0;

  const handleChange = (checkedValues: CheckboxValueType[]) => {
    onChange(checkedValues as string[]); // Explicitly cast to string[]
  };

  return (
    <Popover
      content={
        <Checkbox.Group value={selected} onChange={handleChange}>
          {options.map((option) => (
            <Checkbox key={option} value={option}>
              <FormattedMessage id={labelIds[option]} />
            </Checkbox>
          ))}
        </Checkbox.Group>
      }
      trigger="click"
    >
      <FilterIcon
        className={`${styles.filterIcon} ${isActive ? styles.active : ""}`}
      />
    </Popover>
  );
};

const ProtocolLogsTable: React.FC = () => {
  const protocolLogs: ProtocolLog[] = useSelector(
    (state: RootState) => state.logs.protocolLogsData.logs
  );

  const [selectedProtocols, setSelectedProtocols] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const filters = { protocols: selectedProtocols, statuses: selectedStatuses };
  const filteredLogs = filterLogs(protocolLogs, filters);

  const handleDownloadClick = () => {
    const csvData = transformLogsToCSV(protocolLogs);
    downloadCSV(csvData, "protocol_logs.csv");
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.loginHistory}>
          <FormattedMessage id="loginHistory" />
        </div>
        {protocolLogs?.length ? (
          <button
            onClick={handleDownloadClick}
            className={`${styles.downloadButton} download-logs`}
          >
            <DownloadIcon />
            <FormattedMessage id="download_logs" />
          </button>
        ) : null}
      </div>
      <div className="log-details-container">
        <div className={`${styles.tableWrapper} log-table-wrapper scrollbar`}>
          <table className="log-details">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="timestamp" />
                </th>
                <th>
                  <FormattedMessage id="protocol" />
                  <Filter
                    options={["imap", "smtp", "pop"]}
                    selected={selectedProtocols}
                    onChange={setSelectedProtocols}
                    labelIds={protocolLabelIds}
                  />
                </th>
                <th>
                  <FormattedMessage id="remoteIp" />
                </th>
                <th>
                  <FormattedMessage id="status" />
                  <Filter
                    options={["pass", "fail"]}
                    selected={selectedStatuses}
                    onChange={setSelectedStatuses}
                    labelIds={statusLabelIds}
                  />
                </th>
                <th>
                  <FormattedMessage id="errorCode" />
                </th>
                <th>
                  <FormattedMessage id="clientName" />
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredLogs.length > 0 ? (
                filteredLogs.map((log, index) => (
                  <tr key={index}>
                    <td>{dateTimeFormat(log.timestamp)} (UTC)</td>
                    <td>{log.protocol}</td>
                    <td>{log.remote_ip}</td>
                    <td>{log.status || "-"}</td>
                    <td>{log.err_code || "-"}</td>
                    <td className={styles.clientName}>
                      {log.user_agent || "-"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className={styles.noLogs}>
                    <FormattedMessage id="noDataAvailable" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProtocolLogsTable;
