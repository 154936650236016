import * as React from 'react';
import * as _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { RootState } from '../../reducers';
import { LogDetail, IpLogDetail } from '../../reducers/logsReducer';
import { fetchLogs, fetchIpLogs, updateDefaultNumberOfRecords } from "../../actions/logsActions";
import { ReactComponent as Success } from "../../assets/icon_success.svg"
import { ReactComponent as Failure } from "../../assets/icon_failure.svg"
import { ReactComponent as Deferred } from "../../assets/icon_deferred.svg"
import { ReactComponent as Bounced } from "../../assets/icon_bounced.svg"
import { ReactComponent as ArrowLeft } from "../../assets/arrow_left.svg"
import { ReactComponent as ArrowRight } from "../../assets/arrow_right.svg"
import { ReactComponent as Green } from "../../assets/oval_green.svg"
import { ReactComponent as Red } from "../../assets/oval_red.svg"
import { ReactComponent as DownloadIcon } from "../../assets/download.svg"
import { CopyButton } from "../CopyButton"
import LogDetailsRow from './LogDetailsRow';
import Dropdown, { DropdownOption } from "../Dropdown";
import { ReactComponent as ScrollTop } from "../../assets/scroll_top.svg"
import { DIRECTION, capitalize } from '../utils';
import { FormattedMessage } from "react-intl";
import { dateTimeFormat } from '../../utils/helper';
import { ReactComponent as NoContentIcon } from "../../assets/no-content.svg";
import { copyToClipboard } from '../utils'
import { downloadCSV } from '../../utils/csvUtils';
import ProtocolLogsTable from '../ProtocolLogs/ProtocolLogsTable';
import { ProtocolLog } from '../../reducers/types/logsType';


export const IP_TYPE = {
    REJECT: "reject",
    BLOCK: "block"
}
interface LogDetailsProps {
    fetchLogs: (queryParams: any, token: string) => any,
    fetchIpLogs: (queryParams: any, token: string) => any,
    updateDefaultNumberOfRecords: (noOfRecords: number) => void,
    fetchingLogs: boolean,
    details: LogDetail[],
    ipLogsData: IpLogDetail,
    token: string,
    totalRecords: number,
    senderMailSubmitted: string,
    receiverMailSubmitted: string,
    ipSubmitted: string,
    timestamp: number,
    status: string,
    direction: string,
    recordsPerPage: number,
    page: number,
    defaultNoOfRecords: number,
    protocolLogsData: ProtocolLog[];
}


const recordsPerPageDropdownItems = [
    {
        label: '5',
        value: 5
    },
    {
        label: '10',
        value: 10
    },
    {
        label: '20',
        value: 20
    }
]

interface PropsParent {
    scrollableDivRef: React.RefObject<any>
}

type Props = LogDetailsProps & PropsParent

class LogDetails extends React.PureComponent<Props> {
    state = {
        isScrollToTopVisible: false,
        isMailLogSelected: typeof (this.props.details[0] as LogDetail)?.from !== 'undefined' ? true : false,
        isProtocolLogsSelected: this.props.protocolLogsData,
        numberOfRecordsVisible: this.props.defaultNoOfRecords,
        currentPage: 1
    }
    logDetailsContainerRef: React.RefObject<HTMLDivElement>
    scrollableDiv: HTMLDivElement

    constructor(props: Props) {
        super(props)
        this.getStatusLogo = this.getStatusLogo.bind(this);
        this.toggleVisibility = this.toggleVisibility.bind(this)
        this.handleScrollToTopClick = this.handleScrollToTopClick.bind(this)
        this.getMailLogDetails = this.getMailLogDetails.bind(this)
        this.getIpLogDetails = this.getIpLogDetails.bind(this)
        this.logDetailsContainerRef = React.createRef();
        this.getPagedListForLogs = this.getPagedListForLogs.bind(this)
        this.scrollableDiv = this.props.scrollableDivRef.current
    }

    componentDidMount() {
        this.scrollableDiv.addEventListener('scroll', _.debounce(e => {
            this.toggleVisibility()
        }, 500, { trailing: true, leading: true }))
    }

    toggleVisibility() {
        if (this.scrollableDiv.scrollTop > 0) {
            this.setState({
                isScrollToTopVisible: true
            })
        } else {
            this.setState({
                isScrollToTopVisible: false
            })
        }
    }

    handleScrollToTopClick() {
        if (this.scrollableDiv) {
            this.scrollableDiv.scrollTop = 0
            this.setState({
                isScrollToTopVisible: false
            })
        }
    }

    getStatusLogo = (status: string) => {
      status = capitalize(status);
      let iconComponent = null;
      switch (status) {
          case "Success":
              iconComponent = <Success />;
              break;
          case "Failure":
              iconComponent = <Failure />;
              break;
          case "Deferred":
              iconComponent = <Deferred />;
              break;
          case "Bounced":
              iconComponent = <Bounced />;
              break;
          case "Active":
              iconComponent = <Green />;
              break;
          case "Blocked":
          case "Suspended":
              iconComponent = <Red />;
              break;
      }
      return <div className="status-display">{iconComponent}<span className="log-details-status-text"> {status} </span></div>
    };



    loadPreviousPage = () => {
        if (this.props.fetchingLogs || this.state.currentPage === 1) return
        this.setState({
            currentPage: this.state.currentPage - 1
        })
    }

    loadNextPage = () => {
        if (this.props.fetchingLogs) return
        const recordsShown = this.state.numberOfRecordsVisible * this.state.currentPage
        if (recordsShown < this.props.totalRecords) {
            const shouldFetchNextPage = recordsShown + this.state.numberOfRecordsVisible > this.props.details.length &&
                recordsShown + this.state.numberOfRecordsVisible < this.props.totalRecords
            if (shouldFetchNextPage) {
                if (this.state.isMailLogSelected) {
                    this.props.fetchLogs(this.getFetchLogsQueryParams(this.props.page + 1), this.props.token);
                }
            }
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    }

    getFetchLogsQueryParams = (page: number) => {
        const fetchLogsQueryParams = {
            "from": this.props.senderMailSubmitted.trim(),
            "to": this.props.receiverMailSubmitted.trim(),
            "timestamp": this.props.timestamp,
            "status": this.props.status,
            "direction": this.props.direction,
            "records_per_page": this.props.recordsPerPage,
            "page": page
        }
        return fetchLogsQueryParams
    }

    getFetchIpLogsQueryParams = (page: number) => {
        const fetchLogsQueryParams = {
            "ip": this.props.ipSubmitted,
            "records_per_page": this.props.recordsPerPage,
            "page": page
        }
        return fetchLogsQueryParams
    }


    updateRecordsPerPage = (item: DropdownOption) => {
        if (this.props.fetchingLogs || item.value === this.state.numberOfRecordsVisible) return
        this.props.updateDefaultNumberOfRecords(item.value)
        this.setState({
            currentPage: 1,
            numberOfRecordsVisible: item.value
        })
    }
    getIpRejects = () => {
        return this.props.ipLogsData.rejects;
    };
      
    getIpBlocks = () => {
        return this.props.ipLogsData.blocks;
    };

    getIpLogDetails = () => {
        const rejectHistory = this.getIpRejects();
        const blockedHistory = this.getIpBlocks();
    
        const rejectsDetails = rejectHistory?.map((item, index) => (
          <LogDetailsRow key={`${IP_TYPE.REJECT}-${index}`} item={item} type={IP_TYPE.REJECT} />
        ));
    
        const blocksDetails = blockedHistory?.map((item, index) => (
          <LogDetailsRow key={`${IP_TYPE.BLOCK}-${index}`} item={item} type={IP_TYPE.BLOCK} />
        ));
    
        return { rejectsDetails, blocksDetails };
    }; 
    
    getPagedListForLogs = () => {
        const startIdx = (this.state.currentPage - 1) * this.state.numberOfRecordsVisible
        const endIdx = Math.min(this.state.currentPage * this.state.numberOfRecordsVisible, this.props.totalRecords)
        return (this.props.details).slice(startIdx, endIdx)
    }

    getMailLogDetails = (showFromColumn: boolean, showToColumn: boolean) => {
        const pagedLogs = this.getPagedListForLogs() as LogDetail[]
        return (pagedLogs).map((item: LogDetail) => {
            let columnTag = []
            columnTag.push(<td className="log-details-time">{dateTimeFormat(item.dateTime)} (UTC)</td>)
            { if (showFromColumn) columnTag.push(<td className="log-details-email">{item.from}</td>) }
            { if (showToColumn) columnTag.push(<td className="log-details-email">{item.to}</td>) }
            columnTag.push(<td className="log-details-status">{this.getStatusLogo(item.status)}</td>)
            columnTag.push(<td className="log-details-info">{item.info}</td>)
            columnTag.push(<td className="log-details-status">{item.clientIP}</td>)
            { if (this.props.direction === DIRECTION.OUTGOING) columnTag.push(<td className="log-details-status">{item.isForwarded ? "true" : "false"}</td>) }
            columnTag.push(<td>{capitalize(item.direction)}</td>)
            { if (this.props.direction === DIRECTION.OUTGOING) columnTag.push(<td className="log-details-status">{item.userAgent}</td>) }
            { if (this.props.direction === DIRECTION.INCOMING) columnTag.push(<td className="log-details-status">{item.returnPath}</td>) }
            { if (this.props.direction === DIRECTION.INCOMING) columnTag.push(<td className="log-details-status">{item.originalTo}</td>) }

            columnTag.push(<td className="copy-button">
                <CopyButton onClick={() => copyToClipboard(JSON.stringify(item))} /></td>)
            return <tr>{columnTag}</tr>
        })
    }

    renderMailLogHeader = () => {
        const shouldShowFromColumn = !this.props.senderMailSubmitted
        const shouldShowToColumn = !this.props.receiverMailSubmitted
        return (
          <tr>
            <th className="log-details-time-heading"><FormattedMessage id='heading_date_time' /></th>
            {shouldShowFromColumn && <th className="log-details-email"><FormattedMessage id='from' /></th>}
            {shouldShowToColumn && <th className="log-details-email"><FormattedMessage id='to' /></th>}
            <th className="log-details-status"><FormattedMessage id='status' /></th>
            <th className="log-details-info"><FormattedMessage id='info' /></th>
            <th className="log-details-status"><FormattedMessage id={this.props.direction === DIRECTION.INCOMING ? 'mta_ip' : 'client_ip'} /></th>
            {this.props.direction === DIRECTION.OUTGOING && <th className="log-details-status"><FormattedMessage id='is_forwarded' /></th>}
            <th><FormattedMessage id='direction' /></th>
            {this.props.direction === DIRECTION.OUTGOING && <th className="log-details-status"><FormattedMessage id='user_agent' /></th>}
            {this.props.direction === DIRECTION.INCOMING && <th className="log-details-status"><FormattedMessage id='return_path' /></th>}
            {this.props.direction === DIRECTION.INCOMING && <th className="log-details-status"><FormattedMessage id='original_to' /></th>}
            <th className="copy-icon"></th>
          </tr>
        );
      };
    
      renderIpLogBlockHeader = () => (
        <tr>
          <th><FormattedMessage id='client_ip' /></th>
          <th className="log-details-time-heading" title="DD/MM/YYYY hh:mm:ss"><FormattedMessage id='logCreatedAt' /></th>
          <th className="log-details-time-heading" title="DD/MM/YYYY hh:mm:ss"><FormattedMessage id='logExpireAt' /></th>
          <th><FormattedMessage id='logAction' /></th>
          <th><FormattedMessage id='direction' /></th>
          <th><FormattedMessage id='reason' /></th>
          <th className="copy-icon"></th>
        </tr>
      );
    
      renderIpLogRejectHeader = () => (
        <tr>
          <th><FormattedMessage id='client_ip' /></th>
          <th className="log-details-time-heading" title="DD/MM/YYYY hh:mm:ss"><FormattedMessage id='logDateTime' /></th>
          <th><FormattedMessage id='logInfo' /></th>
          <th><FormattedMessage id='direction' /></th>
          <th><FormattedMessage id='reason' /></th>
          <th className="copy-icon"></th>
        </tr>
      );
    
      renderMailLogTable = () => {
        const shouldShowFromColumn = !this.props.senderMailSubmitted
        const shouldShowToColumn = !this.props.receiverMailSubmitted
        const canDownloadMailLogs = this.props.details.length > 0;

        const handleDownloadClick = () => {
          const formattedMailLogs = this.props.details?.map((item) => ({
            ...item,
            dateTime: item.dateTime ? dateTimeFormat(item.dateTime) : "",
          }));
          const { senderMailSubmitted, receiverMailSubmitted } = this.props;
          const nameParts = [senderMailSubmitted, receiverMailSubmitted].filter(Boolean);
          const fileName = `${nameParts.join("_")}_mail_logs.csv`;
          downloadCSV(formattedMailLogs, fileName);
        };
        return (
          <>
            {canDownloadMailLogs && (
              <button
                onClick={handleDownloadClick}
                className="download-logs"
              >
                <DownloadIcon />
                <FormattedMessage id="download_logs" />
              </button>
            )}
            <div ref={this.logDetailsContainerRef} className="log-details-container">
              <div className="log-table-wrapper scrollbar">
                <table className="log-details">
                  <thead>{this.renderMailLogHeader()}</thead>
                  <tbody>
                    {this.getMailLogDetails(
                      shouldShowFromColumn,
                      shouldShowToColumn
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        );
      };
    
      renderIpLogTable = () => (
        <div className="ip-logs-container">
          <div className="ip-log-details-header">
            <div><strong className="mr1"><FormattedMessage id="ip" /></strong>{this.props.ipSubmitted}</div>
            <div className="status-container"><strong className="mr1"><FormattedMessage id="status_with_colon" /></strong>{this.getStatusLogo(this.props.ipLogsData.status)}</div>
          </div>
          <div>
            <div className="table-title"><FormattedMessage id="blocked_ip_history" /></div>
            {this.getIpLogDetails().blocksDetails.length === 0 ? (
              <div className="no-content-container">
                <NoContentIcon />
                <div className="no-content-text">
                  <p className="no-content-text-line-one"><FormattedMessage id="logs-not-found" /></p>
                </div>
              </div>
            ) : (
              <div className="table-container scrollbar">
                <table className="log-details">
                  <thead>{this.renderIpLogBlockHeader()}</thead>
                  <tbody>{this.getIpLogDetails().blocksDetails}</tbody>
                </table>
              </div>
            )}
          </div>
          <div>
            <div className="table-title"><FormattedMessage id="rejected_ip" /></div>
            {this.getIpLogDetails().rejectsDetails.length === 0 ? (
              <div className="no-content-container">
                <NoContentIcon />
                <div className="no-content-text">
                  <p className="no-content-text-line-one"><FormattedMessage id="logs-not-found" /></p>
                </div>
              </div>
            ) : (
              <div className="table-container scrollbar">
                <table className="log-details">
                  <thead>{this.renderIpLogRejectHeader()}</thead>
                  <tbody>{this.getIpLogDetails().rejectsDetails}</tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      );
    
      renderPagination = () => {
        const totalPages = Math.ceil(this.props.totalRecords / this.state.numberOfRecordsVisible);
        const itemSelectedIndex = recordsPerPageDropdownItems.findIndex(dropDownOption => dropDownOption.value === this.state.numberOfRecordsVisible);
        return (
          <div className="page-div">
            <span className="page-text"><FormattedMessage id='label_records_per_page' /></span>
            <Dropdown
              mainClass="records-div"
              headerClass="records-dd-header"
              headerTitleClass="records-dd-header-title"
              listContainerClass="records-dd-list-container"
              dropDownItemClass="records-dd-menu-item"
              onItemClick={this.updateRecordsPerPage}
              placeholderText=""
              placeholderClass=""
              list={recordsPerPageDropdownItems}
              defaultItemSelectedIndexes={[itemSelectedIndex]}
              shouldAllowMultiSelect={false}
            />
            <span className="page-text">{this.state.currentPage} of {totalPages}</span>
            <div className="page-buttons">
              <ArrowLeft className="prev-page" onClick={this.loadPreviousPage} />
              <ArrowRight className="next-page" onClick={this.loadNextPage} />
            </div>
          </div>
        );
      };
  
      renderLogTable() {
        if (this.state.isMailLogSelected) {
          return this.renderMailLogTable();
        } else if (this.state.isProtocolLogsSelected) {
          return <ProtocolLogsTable />;
        } else {
          return this.renderIpLogTable();
        }
      }

    render() {
        if (this.props.fetchingLogs) {
          return <div className="log-loader"></div>;
        }

        return (
          <div className="logs-container">
            {this.renderLogTable()}
            {this.state.isScrollToTopVisible && (
              <ScrollTop
                className="scroll-to-top-button"
                onClick={this.handleScrollToTopClick}
              />
            )}
            {this.state.isMailLogSelected && this.renderPagination()}
          </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        token: state.auth.token,
        details: state.logs.fetchLogsPayload,
        totalRecords: state.logs.totalRecords,
        senderMailSubmitted: state.logs.senderMailSubmitted,
        receiverMailSubmitted: state.logs.receiverMailSubmitted,
        ipSubmitted: state.logs.ipSubmitted,
        timestamp: state.logs.timestamp,
        status: state.logs.status,
        direction: state.logs.direction,
        recordsPerPage: state.logs.recordsPerPage,
        page: state.logs.page,
        fetchingLogs: state.logs.fetchingLogs,
        defaultNoOfRecords: state.logs.defaultNoOfRecords,
        ipLogsData: state.logs.ipLogsData,
        protocolLogsData: state.logs.protocolLogsData
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ fetchLogs, fetchIpLogs, updateDefaultNumberOfRecords }, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LogDetails);